<script>
import Header from '../components/Header/Header.vue';
export default {
    name: "Home",
    data() {
        return {
            props: {
                "window.screen.width": window.screen.width,
                "window.screen.height": window.screen.height,
                "window.screen.availWidth": window.screen.availWidth,
                "window.screen.availHeight": window.screen.availHeight,
                "window.innerWidth": window.innerWidth,
                "window.innerHeight": window.innerHeight,
                "document.body.clientWidth": document.body.clientWidth,
                "document.body.clientHeight": document.body.clientHeight,
                "document.body.offsetWidth": document.body.offsetWidth,
                "document.body.offsetHeight": document.body.offsetHeight,
                "window.screenTop": window.screenTop,
                "window.screenLeft": window.screenLeft,
                "document.documentElement.clientWidth": document.documentElement.clientWidth,
                "document.documentElement.clientHeight": document.documentElement.clientHeight,
            },
        };
    },
    methods: {
        fetchByTag(tag) {
            if (tag) {
                this.axios.get("/api/apps?tag=" + tag).then((response) => {
                    //console.log(response.data)
                    if (response.data.code == 0) {
                        // this.item = response.data.data;
                        // console.log(item);
                        console.log(response.data.data);
                        this.apps = response.data.data;
                    }
                });
            }
        },
        toApp() {
            window.location.href = this.item.url;
        },
        back() {
            this.$router.push("/");
        },
        check(xyz) {
            if (xyz == this.id) {
                return "primary";
            }
            else {
                return "";
            }
        },
        load(id) {
            console.log("/detail?id=" + id);
            this.id = id;
            this.axios.get("/api/apps/" + id).then((response) => {
                if (response.data.code == 0) {
                    this.item = response.data.data;
                    this.title = this.item.title;
                    this.description = this.item.description;
                    console.log("title:" + this.title);
                    this.poster = "https://img.teslastore.app" + this.item.icon;
                    console.log("poster:" + this.poster);
                    if (this.item.tag) {
                        this.fetchByTag(this.item.tag);
                    }
                }
            });
        },
    },
    mounted() {
        this.id = this.$route.query.id;
        //this.load(this.id);
    },
    created() {
        document.title = "Tesla AppStore - About";
    },
    components: { Header }
};
</script>

<template>
<div>
  <Header></Header>
  <div class="about">
    <h1>Teslastore</h1>
    <p>Teslastore is a store focused on Tesla applications and games. There are plenty of apps and games here.</p>

    <div class="wechat">
        <img style="width: 200px;height:200px;" src="https://img.teslastore.app/icon/wechat_qrcode.jpg">
        <span>微信扫码添加小助手</span>
    </div>

    <!-- <div class="tag"  v-for="app in apps" :key="app.id">
      <el-button :type="check(app.id)" v-on:click="load(app.id)">{{app.title}}</el-button>
    </div>
    <h1 v-for="(prop,key) in props" :key="key">
      {{key}}:{{prop}}
    </h1> -->

  </div>


  
</div>
</template>

<style lang="scss">
  .about {
    margin-block-end: 3rem;
    background-color: #fff;
    padding: 2rem;
    border-radius: 2rem;
    h1 {
      margin-block-end: 1.5rem;
    }
    p {
      font-size: 1.4rem;
    }
  }
  .wechat {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
  }
</style>
