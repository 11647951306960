<script>
import Header from '../components/Header/Header.vue';
export default {
    name: "Home",
    data() {
        return {
            featureds: [],
        };
    },
    methods: {
        toDetail(appId) {
            this.$router.push("/detail?id=" + appId);
        },
        showTitle(item) {
            if (item.series) {
                return item.series;
            }
            else {
                return item.title;
            }
        },
    },
    mounted() {
        this.axios.get("/api/featured").then((response) => {
        //this.axios.get("/api/data.json").then((response) => {
            //dev style, must delete
            //console.log(response.data)
            if (response.data.code == 0) {
                this.featureds = response.data.data;
            }
        });
    },
    created() {
        document.title = "Tesla AppStore";
    },
    components: { Header }
};
</script>

<template>
  <div>
    <Header></Header>
    <section class="featured" v-for="featured in featureds" :key="featured.id">
      <div class="featured__title">
        <span>{{ featured.title }}</span>
      </div>
      <div class="featured__items flex wrap gap-40">
        <div
          class="featured__item"
          v-for="item in featured.apps"
          :key="item.id"
        >
          <div
            v-on:click="toDetail(item.id)"
            class="featured__item__box flex f-column gap-18 a-center"
          >
            <el-image
              class="featured__item__icon"
              :src="'https://img.teslastore.app' + item.icon"
              :alt="item.icon"
            ></el-image>
            <div class="featured__item__title t-center">
              <span>{{ showTitle(item) }}</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<style lang="scss">
.featured {
  margin-block-end: 3rem;
  background-color: #fff;
  padding: 2rem;
  border-radius: 2rem;
}
.featured__title {
  margin-block-end: 1.5rem;
  font-weight: 600;
  span {
    font-size: 1.75rem;
    color: var(--font-color);
    margin-block-end: 1.5rem;
  }
}
.featured__item {
  inline-size: 10.5rem;
}
.featured__item__icon {
  block-size: 9.5rem;
  inline-size: 9.5rem;
  background-color: #fff;
  border-radius: 2rem;
}
.featured__item__title {
  span {
    font-size: 1.5rem;
  }
}
</style>
