<script>
export default {
  name: "Header",
  data() {
    return {
      activeIndex: "/",
      navList: [
        { name: "/", navItem: "主页" },
        { name: "/about", navItem: "关于" },
      ],
      items: ["1", "2", "3", "4", "5", "6"],
      url: "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
      restaurants: [],
      state: "",
      featureds: [],
      timeout: null,
    };
  },
  methods: {
    handleSelect(item) {
      console.log(item);
      //return this.$route.params.username;
      this.$router.push(item);
    },
  },
};
</script>

<template>
  <header class="header flex a-center">
    <div class="header__logo"><a href="/">Tesla AppStore</a></div>
    <div class="header__menu">
      <div class="header__menu__box flex j-center gap-5">
        <router-link
          class="t-center block"
          v-for="item in navList"
          :to="item.name"
          :key="item.name"
          :index="item.name"
          >{{ item.navItem }}</router-link>
      </div>
    </div>
  </header>
</template>

<style lang="scss">
/* header */
.header {
  padding: 1.125rem 0;
  margin-block-end: 1.25rem;
}
.header__logo {
  position: absolute;
  a {
    font-size: 28px;
    font-weight: 400;
  }
}
.header__menu {
  inline-size: 100%;
}
.header__menu__box {
  a {
    font-size: 1.75rem;
    color: var(--font-small-color);
    inline-size: 4.9em;
    block-size: 2.3em;
    border-radius: 100px;
    line-height: 2.3em;
    &:hover {
      color: var(--font-color);
    }
  }
  .router-link-exact-active {
    background-color: var(--brand-color);
    color: #fff;
    &:hover {
      color: #fff;
    }
  }
}
@media screen and (max-width: 768px) {
  .header {
    flex-direction: column;
    gap: 18px;
    .header__logo {
      position: relative;
    }
  }
}
</style>
