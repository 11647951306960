<script>
export default {
  name: "App",
};
</script>

<template>
  <div id="app">
    <div class="container">
      <div class="main">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import url(./assets/style/resets.scss);

/* container */
.container {
  padding: 0 2rem;
  width: 100vw;
}
</style>
