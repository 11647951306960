<script>
export default {
  name: "Home",
  data() {
    return {
      dialogVisible: false,
      item: {},
      poster: "https://img.teslastore.app/poster/default_icon.png",
      id: "",
      title: "加载中",
      description: "",
      apps: [],
      //poster:"https://www.baidu.com/img/PCtm_d9c8750bed0b3c7d089fa7d55720d6cf.png"
    };
  },
  methods: {
    fetchByTag(tag) {
      if (tag) {
        this.axios.get("/api/apps?tag=" + tag).then((response) => {
          //console.log(response.data)
          if (response.data.code == 0) {
            // this.item = response.data.data;
            // console.log(item);
            console.log(response.data.data);
            this.apps = response.data.data;
          }
        });
      }
    },
    isGame() {
      return (
        this.item.category == "街机" ||
        this.item.category == "FC" ||
        this.item.category == "GBA" ||
        this.item.category == "MD"
      );
    },
    isFullScreen() {
      if (window.innerWidth < 1200 && window.innerHeight < 980) {
        return false;
      } else {
        return true;
      }
    },
    runApp(force) {
      if (force || this.item.isFullScreen != 1 || this.isFullScreen()) {
        this.toApp();
      } else {
        this.dialogVisible = true;
      }
    },
    toApp() {
      this.dialogVisible = false;
      window.location.href = this.item.url;
    },
    back() {
      this.$router.push("/");
    },
    toFullScreenHelper() {
      console.log("toFullScreenHelper");
      this.dialogVisible = false;
      this.load(60006);
    },
    check(xyz) {
      if (xyz == this.id) {
        return "primary";
      } else {
        return "";
      }
    },

    load(id) {
      console.log("/detail?id=" + id);
      this.id = id;
      this.axios.get("/api/apps/" + id).then((response) => {
      //this.axios.get("/api/app.json").then((response) => {   //dev style, must delete
      //this.axios.get("/api/game.json").then((response) => {
        //dev style, must delete
        if (response.data.code == 0) {
          this.item = response.data.data;
          this.title = this.item.title;
          this.description = this.item.description;
          //console.log("title:" + this.title)
          this.poster = "https://img.teslastore.app" + this.item.icon;
          //console.log("poster:" + this.poster);
          if (this.item.tag) {
            this.fetchByTag(this.item.tag);
          } else {
            this.apps = [];
          }
        }
      });
    },
  },
  mounted() {
    this.id = this.$route.query.id;
    this.load(this.id);
  },
  created () {
    document.title = "Tesla AppStore - Detail"
  },
};
</script>

<template>
  <div class="app-container">
    <div class="btn--back">
      <span class="t-center block" v-on:click="back()"
        ><i class="el-icon-arrow-left"></i
      ></span>
    </div>
    <div class="description flex space-between">
      <div class="app__box flex f-column gap-24">
        <div class="app__title">
          <span>{{ title }}</span>
        </div>
        <div class="app__prop flex gap-12" v-if="isGame()">
          <span>玩家：{{ item.player }}</span>
          <span>支持：{{ item.controller }}</span>
        </div>
        <div class="app__description">
          <span v-html="description">
          </span>
        </div>
        <div class="app__btn">
          <span class="t-center block" v-on:click="runApp()">运行</span>
        </div>
      </div>
      <el-image class="app__image" :src="poster" fit="fill"></el-image>
    </div>

    <!-- any recommend games -->
    <!-- <div class="game-list flex f-column">
      <div class="game-list__title">
        <span>相关游戏</span>
      </div>
      <div class="game-list__box flex gap-40">
        <div class="game-list__game" v-for="app in apps" :key="app.id">
          <el-image
            class="game-list__game__image"
            :src="poster"
            fit="fill"
          ></el-image>
          <div class="game-list__game__title t-center">
            <span>{{ app.title }}</span>
          </div>
        </div>
      </div>
    </div> -->

    <el-row :gutter="20">
      <!-- <el-col class="tag" :span="6" v-for="app in apps" :key="app.id">
        <el-button :type="check(app.id)" v-on:click="load(app.id)">{{app.title}}</el-button>
      </el-col> -->
      <div style="display: flex; flex-wrap: wrap">
        <div class="tag " v-for="app in apps" :key="app.id">
          <el-button :type="check(app.id)" v-on:click="load(app.id)">
            <span>{{app.title}}</span>
          </el-button>
        </div>
      </div>
    </el-row>
    <el-dialog title="提示" :visible.sync="dialogVisible" >
      <span class="dialog_desc">此应用强烈建议在全屏模式下运行，否则可能运行不流畅</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="toApp()"><span class="dialog_button">仍然运行</span></el-button>
        <el-button type="primary" @click="toFullScreenHelper()"><span class="dialog_button">跳转到全屏助手</span></el-button>
      </span>
    </el-dialog>
  </div>
</template>

<style lang="scss">
.btn--back {
  margin: 2.375rem 0;
  span {
    font-size: 2.25rem;
    inline-size: 2em;
    block-size: 2em;
    background-color: #fff;
    border-radius: 50%;
    line-height: 2em;
    &:hover {
      color: #fff;
      background-color: var(--brand-color);
    }
  }
}
.description {
  background-color: #fff;
  padding: 2rem;
  border-radius: 2rem;
  margin-block-end: 2.375rem;
}
.app__box {
  max-inline-size: 36rem;
  .app__title {
    font-size: 1.75rem;
    font-weight: 600;
    color: var(--font-color);
  }
  .app__prop {
    span {
      padding: 5px 12px;
      background-color: #f0f0f0;
      color: var(--font-color);
      border-radius: 2rem;
    }
  }
  .app__description {
    font-size: 1.5rem;
    color: var(--font-small-color);
    line-height: 2rem;
  }
  .app__btn {
    margin-block-start: 1.5rem;
    span {
      font-size: 1.75rem;
      inline-size: 22rem;
      block-size: 5rem;
      line-height: 5rem;
      border-radius: 100px;
      border: 1px solid var(--font-color);
      &:hover {
        color: #fff;
        background-color: var(--brand-color);
        border: none;
      }
    }
  }
}
.app__image {
  inline-size: 18.75rem;
  block-size: 18.75rem;
  border-radius: 2rem;
}

.game-list {
  margin-block-end: 2.375rem;
}
.game-list__title {
  margin-block-end: 1.5rem;
  span {
    font-size: 1.75rem;
    color: var(--font-color);
    margin-block-end: 1.5rem;
  }
}
.game-list__game {
  inline-size: 10.5rem;
}
.game-list__game__image {
  block-size: 9.5rem;
  inline-size: 9.5rem;
  background-color: #fff;
  border-radius: 2rem;
}
.game-list__game__title {
  span {
    font-size: 1.5rem;
  }
}
.tag {
  margin: 0.5rem;
  span {
    font-size: 1.75rem;
  }
}
.dialog_title {
  font-size: 1.75rem;
}
.dialog_desc {
  font-size: 1.75rem;
}

.dialog_button {
  font-size: 1.75rem;
}


@media screen and (max-width: 1024px) {
  .description {
    flex-direction: column-reverse;
    align-items: center;
    gap: 2.375rem;
  }
  .app__box {
    max-inline-size: 100%;
    .app__btn {
      margin: 0 auto;
    }
  }
}
</style>
